/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ResourceEditor } from 'm6BoBuilder';
// Libs
//import { Card, CardContent, CardHeader } from '@mui/material';

import { ResourceType } from 'm6BoBuilder/types/ResourceType';
import { RecipeIngredientType } from 'models/RecipeIngredient';
import { useNavigate } from 'react-router-dom';

type RecipeVersionInfosPropsType = {
    id?: string | number | undefined;
    recipe?: any;
    title?: string;
    resource: ResourceType;
    errors?: any;
    onUpdateValues?: (values: Record<string, any>) => void;
};

const RecipeVersionInfos = ({
    id,
    title,
    recipe,
    resource: { actions, service, resourcePath, configForm, validationSchema, placeHolder },
    errors,
    onUpdateValues,
}: RecipeVersionInfosPropsType): JSX.Element => {
    const navigate = useNavigate();

    let defaultValues;
    if (recipe && !id) {
        const { title, recipeIngredients, portion, personNumber } = recipe;
        const versionIngredients = recipeIngredients.map(({ ingredient, position, quantity }: RecipeIngredientType) => {
            return { ingredient: ingredient['@id'], position, quantity };
        });
        defaultValues = { recipe: recipe['@id'], title, versionIngredients, portion, personNumber };
    }

    return (
        <>
            <ResourceEditor
                id={id}
                resourceService={service}
                resourceType={resourcePath}
                defaultValues={defaultValues}
                config={{
                    ...configForm,
                    ...{
                        afterSubmit: (entity: Record<string, unknown>): boolean => {
                            navigate(0);
                            return false;
                        },
                    },
                }}
                validationSchema={validationSchema}
                placeHolder={placeHolder}
                showHelpCta={false}
                actions={actions}
                setResourceValue={(data) => {
                    if (onUpdateValues) {
                        onUpdateValues(data);
                    }
                }}
                errorsAll={errors}
            />
            {/*<Card>
                {title && <CardHeader title={title} />}
                <CardContent>
                    <ResourceEditor
                        id={id}
                        resourceService={service}
                        resourceType={resourcePath}
                        config={configForm}
                        validationSchema={validationSchema}
                        placeHolder={placeHolder}
                        showHelpCta={false}
                        setResourceValue={(data) => onUpdateValues(data)}
                        noAction={true}
                        errorsAll={errors}
                    />
                </CardContent>
            </Card>*/}
        </>
    );
};

export default RecipeVersionInfos;
