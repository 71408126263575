/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal, Box, TextField, FormControlLabel, Checkbox, Button } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import '../../assets/css/FormEditor.css';
import { useUploadMedia } from 'm6BoBuilder/services/useQueries';
import { HydraResource } from 'm6BoBuilder/interfaces/hydra.interface';

const isTargetBlankRegex = /\?isBlank=true/;
const Link = Quill.import('formats/link');
Quill.debug('error');

class CustomLink extends Link {
    static create(value: string) {
        const isTargetBlank = isTargetBlankRegex.test(value);
        const node = Link.create(value);
        value = Link.sanitize(value).replace('?isBlank=true', '');

        node.setAttribute('href', value);
        node.setAttribute('target', '_blank');
        if (!isTargetBlank) {
            node.removeAttribute('target');
        }

        return node;
    }

    format(name: any, value: any) {
        const isTargetBlank = isTargetBlankRegex.test(value);
        if (value) {
            value = Link.sanitize(value).replace('?isBlank=true', '');
            super.format(name, value);
            if (isTargetBlank) {
                this.domNode.setAttribute('target', '_blank');
            } else {
                this.domNode.removeAttribute('target');
            }
        } else {
            super.format(name, value);
        }
    }
}

Quill.register(CustomLink);

type FormEditorProps = {
    value: string;
    setValue: (s: string) => void;
    readOnly: boolean;
};
const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column' as const,
};

const FormEditor = ({ value, setValue, readOnly }: FormEditorProps): JSX.Element => {
    const { mutateAsync: UploadMutateAsync } = useUploadMedia();

    const [quill, setQuill] = useState<any>();
    const [open, setOpen] = useState(false);
    const [linkUrl, setLinkUrl] = useState('');
    const [openNewTab, setOpenNewTab] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        if (quill) {
            quill.focus();
            quill.format('link', linkUrl + (openNewTab ? '?isBlank=true' : ''));
            setLinkUrl('');
            setOpenNewTab(false);
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const linkTest = useCallback(function (this: any, value: any) {
        handleOpen();
        setQuill(this.quill);
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const imageHandler = useCallback(function (this: any, value: any) {
        const input = document.createElement('input');

        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files?.length ? input.files[0] : null;
            const formData = new FormData();

            if (file) {
                formData.append('image', file);
            } else return;

            // Save current cursor state
            const range = this.quill.getSelection(true);

            // Move cursor to right side of image (easier to continue typing)
            this.quill.setSelection(range.index + 1);

            await UploadMutateAsync({
                resourcePath: 'media_objects',
                file,
                onSuccess: (data: HydraResource) => {
                    // Insert uploaded image
                    this.quill.insertEmbed(range.index, 'image', process.env.REACT_APP_API_URL + data.large);
                },
            });
        };
    }, []);

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'clean'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ align: '' }, { align: 'center' }, { align: 'right' }],
                    ['link', 'image'],
                ],
                handlers: {
                    link: linkTest,
                    image: imageHandler,
                },
            },
        };
    }, []);

    const formats = useMemo(
        () => [
            'header',
            'font',
            'size',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'indent',
            'link',
            'image',
            'color',
            'align',
        ],
        [],
    );

    return (
        <>
            <ReactQuill
                modules={modules}
                formats={formats}
                theme="snow"
                value={value}
                onChange={setValue}
                readOnly={readOnly}
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <TextField
                        id="link-url"
                        type="text"
                        label="Link URL"
                        name="link-url"
                        onChange={(e) => {
                            setLinkUrl(e.target.value);
                        }}
                        value={linkUrl}
                        variant={'outlined'}
                    />
                    <FormControlLabel
                        control={<Checkbox value={openNewTab} onChange={() => setOpenNewTab(!openNewTab)} />}
                        label="Ouvrir dans un nouvel onglet"
                    />
                    <Button onClick={handleClose}>Valider</Button>
                </Box>
            </Modal>
        </>
    );
};

export default FormEditor;
