/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApiErrorManager } from 'm6BoBuilder/hooks/useApiErrorManager';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
//import Cookies from 'universal-cookie';

// MODELS
import { MutationUser } from 'models/User';
import {
    featureApi,
    ingredientApi,
    menuApi,
    promoCodeGroupApi,
    recipeApi,
    statisticsApi,
    userApi,
    userCBApi,
    userYCApi,
} from './routes';
import { AxiosError } from 'axios';
import { flashMessage, flashType } from 'm6BoBuilder/components/Flash';
import { MutationMenu } from 'models/Menu';
import { FeatureUpdate } from 'models/Feature';
import { queryKeys as defaultQueryKeys } from 'm6BoBuilder/services/useQueries';

export const queryKeys = {
    ...defaultQueryKeys,
    getUser: ['getUser'],
    getUserLogs: ['getUserLogs'],
    getUsers: ['getUsers'],
    getUserCB: ['getUserCB'],
    getUserYC: ['getUserYC'],
    getMenus: ['getMenus'],
    getVariants: ['getVariants'],
    getRecipes: ['getRecipes'],
    getRecipe: ['getRecipe'],
    getIngredients: ['getIngredients'],
    getIngredient: ['getIngredient'],
    getPromoCodeGroupExport: ['getPromoCodeGroupExport'],
    getStatistics: ['getStatistics'],
};

// QUERIES

// USER //
export const useGetUsers = () => {
    const sendApiErrorManager = useApiErrorManager();
    return useQuery(
        [...queryKeys.getUsers],
        async () => {
            const response = await userApi.users();
            const list = response['hydra:member'];
            const totalItems = response['hydra:totalItems'];
            return { list, totalItems };
        },
        {
            onError: (error: unknown) => {
                void sendApiErrorManager(error, "Nous n'avons pas réussi à récupérer la liste des utilisateurs");
            },
        },
    );
};

export const useGetUser = (userId?: number | string) => {
    const sendApiErrorManager = useApiErrorManager();
    return useQuery(
        [...queryKeys.getUser, userId],
        async () => {
            if (!userId) {
                return;
            }
            return await userApi.user(userId as number);
        },
        {
            onError: (error: unknown) => {
                void sendApiErrorManager(error, "Nous n'avons pas réussi à récupérer l'utilisateur");
            },
        },
    );
};

export const useGetUserLogs = (userId: number | string, filters: Record<string, any>) => {
    const sendApiErrorManager = useApiErrorManager();
    return useQuery(
        [...queryKeys.getUserLogs, filters],
        async () => {
            const response = await userApi.userLogs(userId as number, { ...filters });

            const list = response['hydra:member'];
            const totalItems = response['hydra:totalItems'];
            return { list, totalItems };
        },
        {
            onError: (error: unknown) => {
                void sendApiErrorManager(error, "Nous n'avons pas réussi à récupérer la liste des logs utilisateurs");
            },
        },
    );
};

export const useGetUserCB = (userId?: number | string, queryOptions: any = null) => {
    const sendApiErrorManager = useApiErrorManager();
    return useQuery(
        [...queryKeys.getUserCB, userId],
        async () => {
            if (!userId) {
                return;
            }
            return await userCBApi.userDetails(userId as number);
        },
        {
            onError: (error: unknown) => {
                void sendApiErrorManager(error, "Nous n'avons pas réussi à récupérer l'utilisateur CB");
            },
            ...queryOptions,
        },
    );
};

export const useGetUserYC = (userId?: number | string, queryOptions: any = null) => {
    const sendApiErrorManager = useApiErrorManager();
    return useQuery(
        [...queryKeys.getUserYC, userId],
        async () => {
            if (!userId) {
                return;
            }
            return await userYCApi.userDetails(userId as number);
        },
        {
            onError: (error: unknown) => {
                void sendApiErrorManager(error, "Nous n'avons pas réussi à récupérer l'utilisateur YC");
            },
            ...queryOptions,
        },
    );
};

// MENU //

export const useGetMenus = (filters: Record<string, any>) => {
    const sendApiErrorManager = useApiErrorManager();
    return useQuery(
        [...queryKeys.getMenus, filters],
        async ({ queryKey }: { queryKey: any[] }) => {
            const [_key, queryFilters] = queryKey;
            const response = await menuApi.getAll({ ...queryFilters });
            const list = response['hydra:member'];
            const totalItems = response['hydra:totalItems'];
            return { list, totalItems };
        },
        {
            onError: (error: unknown) => {
                void sendApiErrorManager(error, "Nous n'avons pas réussi à récupérer la liste des menus");
                flashMessage(`Nous n'avons pas réussi à récupérer la liste des menus. \n${error}`, flashType.ERROR);
            },
        },
    );
};

export const useGetVariants = (
    filters: Record<string, any>,
    dietTypeId: number,
    selectedMealRecipeId?: number,
    queryOptions: any = null,
) => {
    const sendApiErrorManager = useApiErrorManager();
    return useQuery(
        [...queryKeys.getVariants, filters],
        async ({ queryKey }: { queryKey: any[] }) => {
            const [_key, queryFilters] = queryKey;
            let response;

            const cleanQueryFilters = Object.fromEntries(
                Object.entries({ ...queryFilters }).filter(([_, value]) => value != null && value !== ''),
            );

            if (selectedMealRecipeId) {
                response = await menuApi.getVariantsByMealRecipeId(selectedMealRecipeId, { ...cleanQueryFilters });
            } else {
                response = await menuApi.getVariantsByDietType(dietTypeId, { ...cleanQueryFilters });
            }
            const list = response['hydra:member'];
            const totalItems = response['hydra:totalItems'];
            return { list, totalItems };
        },
        {
            onError: (error: unknown) => {
                void sendApiErrorManager(error, "Nous n'avons pas réussi à récupérer la liste des variants");
                flashMessage(`Nous n'avons pas réussi à récupérer la liste des variants. \n${error}`, flashType.ERROR);
            },
            ...queryOptions,
        },
    );
};

// RECIPE //

export const useGetRecipes = (filters?: Record<string, any>) => {
    const sendApiErrorManager = useApiErrorManager();
    return useQuery(
        [...queryKeys.getRecipes, filters],
        async ({ queryKey }: { queryKey: any[] }) => {
            const [_key, queryFilters] = queryKey;
            const response = await recipeApi.getAll({ ...queryFilters });
            const list = response['hydra:member'];
            const totalItems = response['hydra:totalItems'];
            return { list, totalItems };
        },
        {
            onError: (error: unknown) => {
                void sendApiErrorManager(error, "Nous n'avons pas réussi à récupérer la liste des recettes");
                flashMessage(`Nous n'avons pas réussi à récupérer la liste des menus. \n${error}`, flashType.ERROR);
            },
        },
    );
};

export const useGetRecipe = (recipeId?: number | string) => {
    const sendApiErrorManager = useApiErrorManager();
    return useQuery(
        [...queryKeys.getRecipe, recipeId],
        async () => {
            return await recipeApi.get(recipeId as number);
        },
        {
            onError: (error: unknown) => {
                void sendApiErrorManager(error, `Nous n'avons pas réussi à récupérer la recette ${recipeId}.`);
                flashMessage(`Nous n'avons pas réussi à récupérer la recette ${recipeId}. \n${error}`, flashType.ERROR);
            },
            enabled: !!recipeId,
        },
    );
};

// INGREDIENT //

export const useGetIngredients = (filters?: Record<string, any>) => {
    const sendApiErrorManager = useApiErrorManager();
    return useQuery(
        [...queryKeys.getIngredients, filters],
        async ({ queryKey }: { queryKey: any[] }) => {
            const [_key, queryFilters] = queryKey;
            const response = await ingredientApi.getAll({ ...queryFilters });
            const list = response['hydra:member'];
            const totalItems = response['hydra:totalItems'];
            return { list, totalItems };
        },
        {
            onError: (error: unknown) => {
                void sendApiErrorManager(error, "Nous n'avons pas réussi à récupérer la liste des ingredients");
                flashMessage(`Nous n'avons pas réussi à récupérer la liste des menus. \n${error}`, flashType.ERROR);
            },
        },
    );
};

export const useGetIngredient = (ingredientId?: number | string) => {
    const sendApiErrorManager = useApiErrorManager();
    return useQuery(
        [...queryKeys.getIngredient, ingredientId],
        async () => {
            return await ingredientApi.get(ingredientId as number);
        },
        {
            onError: (error: unknown) => {
                void sendApiErrorManager(error, `Nous n'avons pas réussi à récupérer l'ingredient ${ingredientId}.`);
                flashMessage(
                    `Nous n'avons pas réussi à récupérer l'ingredient ${ingredientId}. \n${error}`,
                    flashType.ERROR,
                );
            },
            enabled: !!ingredientId,
        },
    );
};

// PROMO CODE GROUP //

export const useGetPromoCodeGroupExport = (promoCodeGroupId?: number | string) => {
    const sendApiErrorManager = useApiErrorManager();
    return useQuery(
        [...queryKeys.getPromoCodeGroupExport, promoCodeGroupId],
        async () => {
            return await promoCodeGroupApi.export(promoCodeGroupId as number);
        },
        {
            onError: (error: unknown) => {
                const errorMessage = `Nous n'avons pas réussi à récupérer l'export de codes promos ${promoCodeGroupId}.`;
                void sendApiErrorManager(error, errorMessage);
                flashMessage(`${errorMessage}. \n${error}`, flashType.ERROR);
            },
            enabled: false,
        },
    );
};

// STATISTICS //

export const useGetStatistics = (path?: string) => {
    const sendApiErrorManager = useApiErrorManager();
    return useQuery(
        [...queryKeys.getStatistics, path],
        async () => {
            const response = await statisticsApi.getAll(path);
            const { data } = response;
            return data;
        },
        {
            onError: (error: unknown) => {
                const errorMessage = `Nous n'avons pas réussi à récupérer les données ${path}.`;
                void sendApiErrorManager(error, errorMessage);
                flashMessage(`${errorMessage}. \n${error}`, flashType.ERROR);
            },
            enabled: !!path,
        },
    );
};

// MUTATIONS

// FEATURES //

export const useUpdateFeature = () => {
    const sendApiErrorManager = useApiErrorManager();
    const queryClient = useQueryClient();

    return useMutation(
        ({ featureId, data }: { featureId: number | string; data: FeatureUpdate | any }) =>
            featureApi.updateFeature(featureId as number, data),
        {
            onSuccess: async (data, { featureId }, context) => {
                flashMessage(
                    `Feature ${data.name}(#${featureId}) ${data.activate ? 'activé' : 'désactivé'}`,
                    flashType.SUCCESS,
                );
                void queryClient.invalidateQueries([...queryKeys.getResources]);
                return data;
            },
            onError: (error, { featureId, data }, context) => {
                const errorMessage = `Nous n'avons pas réussi à ${
                    data.activate ? 'activer' : 'désactiver'
                } la feature #${featureId}.`;
                void sendApiErrorManager(error, errorMessage);
                flashMessage(errorMessage, flashType.ERROR);
            },
        },
    );
};

// USER //
/*export const useMutationGetUser = () => {
    const sendApiErrorManager = useApiErrorManager();
    return useMutation((userId: number) => userApi.user(userId), {
        onError: (error) => {
            void sendApiErrorManager(error, "Nous n'avons pas réussi à récupérer l'utilisateur");
        },
    });
};*/

export const useUpdateUser = () => {
    const sendApiErrorManager = useApiErrorManager();
    const queryClient = useQueryClient();

    return useMutation(({ userId, data }: MutationUser) => userApi.update(userId as number, data), {
        onSuccess: (data) => {
            void queryClient.invalidateQueries([...queryKeys.getUsers]);
            return data;
        },
        onError: (error) => {
            void sendApiErrorManager(error, "Erreur lors de la modification de l'utilisateur");
        },
    });
};

export const usePostUserCB = () => {
    const sendApiErrorManager = useApiErrorManager();
    //const queryClient = useQueryClient();

    return useMutation((data: any) => userCBApi.create(data), {
        onSuccess: (data) => {
            //void queryClient.invalidateQueries([...queryKeys.getUserCB]);
            return data;
        },
        onError: (error) => {
            void sendApiErrorManager(error, "Erreur lors de la création de l'utilisateur CB");
        },
    });
};

export const useUpdateUserCB = () => {
    const sendApiErrorManager = useApiErrorManager();
    //const queryClient = useQueryClient();

    return useMutation(({ userId, data }: MutationUser) => userCBApi.update(userId as number, data), {
        onSuccess: (data) => {
            //void queryClient.invalidateQueries([...queryKeys.getUserCB, userId]);
            return data;
        },
        onError: (error) => {
            void sendApiErrorManager(error, "Erreur lors de la modification de l'utilisateur CB");
        },
    });
};

export const usePostUserYC = () => {
    const sendApiErrorManager = useApiErrorManager();
    //const queryClient = useQueryClient();

    return useMutation((data: any) => userYCApi.create(data), {
        onSuccess: (data) => {
            //void queryClient.invalidateQueries([...queryKeys.getUserYC]);
            return data;
        },
        onError: (error) => {
            void sendApiErrorManager(error, "Erreur lors de la création de l'utilisateur YC");
        },
    });
};

export const useUpdateUserYC = () => {
    const sendApiErrorManager = useApiErrorManager();
    //const queryClient = useQueryClient();

    return useMutation(({ userId, data }: MutationUser) => userYCApi.update(userId as number, data), {
        onSuccess: (data) => {
            //void queryClient.invalidateQueries([...queryKeys.getUserYC, userId]);
            return data;
        },
        onError: (error) => {
            void sendApiErrorManager(error, "Erreur lors de la modification de l'utilisateur YC");
        },
    });
};

export const useImpersonate = () => {
    const sendApiErrorManager = useApiErrorManager();

    return useMutation((userId: number | string) => userApi.impersonate(userId as number), {
        onSuccess: (data, userId) => {
            /*const cookies = new Cookies();
            const d = new Date();
            d.setDate(d.getDate() + 1);
            const cookiesOptions = { path: '/', domain: '.croq-kilos.com', maxAge: 24, expires: d };
            cookies.set('uid', data.uid, cookiesOptions);
            cookies.set('jwt', data.token, cookiesOptions);
            cookies.set('reft', data.refreshToken, cookiesOptions);
            cookies.set('impersonate', true, cookiesOptions);
            window.open(`${process.env.REACT_APP_WWW_URL}`, '_blank');*/
            return data;
        },
        onError: (error) => {
            void sendApiErrorManager(error, 'Erreur lors de la requête');
        },
    });
};

export const useUnsubscribeUser = () => {
    const sendApiErrorManager = useApiErrorManager();
    //const queryClient = useQueryClient();

    return useMutation(
        ({ userId, params }: { userId: number | string; params: any }) => userApi.unsubscribe(userId as number, params),
        {
            onSuccess: (data, { userId, params }) => {
                return data;
            },
            onError: (error) => {
                void sendApiErrorManager(error, 'Error during unsubscription request');
            },
        },
    );
};

export const useDeleteUser = () => {
    const sendApiErrorManager = useApiErrorManager();
    const queryClient = useQueryClient();

    return useMutation((userId: number | string) => userApi.delete(userId as number), {
        onSuccess: (data) => {
            flashMessage(`Compte supprimé avec succès.`, flashType.SUCCESS);
            void queryClient.invalidateQueries([...queryKeys.getUsers]);
        },
        onError: (error: AxiosError) => {
            void sendApiErrorManager(
                error,
                `API response : ${error.response?.data['hydra:description'] ?? error.message}`,
            );
        },
    });
};

export const useMergeUser = () => {
    const sendApiErrorManager = useApiErrorManager();
    const queryClient = useQueryClient();

    return useMutation(
        ({ userId, userToMerge }: { userId: number | string; userToMerge: string }) =>
            userApi.merge(userId as number, userToMerge),
        {
            onSuccess: (data) => {
                flashMessage(`Comptes fusionnés avec succès.`, flashType.SUCCESS);
                void queryClient.invalidateQueries([...queryKeys.getUsers]);
                return data;
            },
            onError: (error: AxiosError) => {
                void sendApiErrorManager(
                    error,
                    `Erreur lors de la fusion des comptes \nAPI response : ${
                        error.response?.data['hydra:description'] ?? error.message
                    }`,
                );
            },
        },
    );
};

// RECIPE //

export const useDuplicateRecipe = () => {
    const sendApiErrorManager = useApiErrorManager();
    const queryClient = useQueryClient();

    return useMutation((recipeId: number | string) => recipeApi.duplicate(recipeId as number), {
        onSuccess: async (data, recipeId, context) => {
            flashMessage(`Recette ${recipeId} dupliquée`, flashType.SUCCESS);
            void queryClient.invalidateQueries([queryKeys.getMenus]);
            return data;
        },
        onError: (error, recipeId, context) => {
            void sendApiErrorManager(error, `Nous n'avons pas réussi à dupliquer la recette ${recipeId}.`);
            flashMessage(`Nous n'avons pas réussi à dupliquer la recette ${recipeId}. \n${error}`, flashType.ERROR);
        },
    });
};

// MENU //

export const useCreateMenuMeal = () => {
    const sendApiErrorManager = useApiErrorManager();
    const queryClient = useQueryClient();

    return useMutation((data: any) => menuApi.createMenuMeal(data), {
        onSuccess: async (data, variables, context) => {
            flashMessage(`Recette ajoutée`, flashType.SUCCESS);
            void queryClient.invalidateQueries([...queryKeys.getMenus]);
            return data;
        },
        onError: (error, variables, context) => {
            void sendApiErrorManager(error, `Erreur lors de l'ajout de la recette. \n${error}`);
            //flashMessage(`Erreur lors de l'ajout de la recette. \n${error}`, flashType.ERROR);
        },
    });
};

export const useUpdateStateMenu = () => {
    const sendApiErrorManager = useApiErrorManager();

    return useMutation(({ menuId, data }: MutationMenu) => menuApi.updateMenu(menuId, { state: data.state }), {
        onSuccess: async (data, variables, context) => {
            const { id, state, publishedAt } = variables.data;
            flashMessage(
                `Etat (${state ? 'validé' : 'non validé'}) du menu du ${format(
                    new Date(publishedAt),
                    'cccc dd LLLL yyyy',
                    {
                        locale: fr,
                    },
                )} (id:${id}) enregistré`,
                flashType.SUCCESS,
            );
            return data;
        },
        onError: (error, variables, context) => {
            void sendApiErrorManager(error, `Erreur lors de la modification de la recette. \n${error}`);
            //flashMessage(`Erreur lors de la modification de la recette. \n${error}`, flashType.ERROR);
        },
    });
};

export const useUpdateMealRecipe = () => {
    const sendApiErrorManager = useApiErrorManager();
    const queryClient = useQueryClient();
    return useMutation(
        ({ mealRecipeId, data }: { mealRecipeId: number; data: any }) => menuApi.updateMealRecipe(mealRecipeId, data),
        {
            onSuccess: async (data, { mealRecipeId }, context) => {
                flashMessage(`MealRecipe #${mealRecipeId} a bien été mis à jour.`, flashType.SUCCESS);
                void queryClient.invalidateQueries([...queryKeys.getMenus]);
            },
            onError: (error, { mealRecipeId }, context) => {
                void sendApiErrorManager(error, `Erreur lors de la suppressim de la recette. \n${error}`);
                flashMessage(`MealRecipe #${mealRecipeId} :Erreur lors de la mise à jour. \n${error}`, flashType.ERROR);
            },
        },
    );
};

export const useDeleteMealRecipe = () => {
    const sendApiErrorManager = useApiErrorManager();
    const queryClient = useQueryClient();
    return useMutation((mealRecipeId: number) => menuApi.deleteMealRecipe(mealRecipeId as number), {
        onSuccess: async (data, mealRecipeId, context) => {
            flashMessage(`MealRecipe #${mealRecipeId} a bien été supprimée.`, flashType.SUCCESS);
            void queryClient.invalidateQueries([...queryKeys.getMenus]);
        },
        onError: (error, mealRecipeId, context) => {
            if (!mealRecipeId) {
                // force l'invalidation si mealRecipeId est undefined ( suppression des meal recipe vide)
                void queryClient.invalidateQueries([...queryKeys.getMenus]);
                return;
            }
            void sendApiErrorManager(error, `Erreur lors de la suppressim de la recette. \n${error}`);
            flashMessage(`Erreur lors de la suppression. \n${error}`, flashType.ERROR);
        },
    });
};
