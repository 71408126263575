/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { IconButton } from '@mui/material';
import { ActionType, ActionTypeProps, CustomLoader } from 'm6BoBuilder';
import { useGetPromoCodeGroupExport } from 'services/useQueries';

const AsyncCSVLinkAction: ActionType = ({ config, id }: ActionTypeProps): JSX.Element => {
    const { isFetching, refetch, data: promoCodeExportData } = useGetPromoCodeGroupExport(id);
    const [csvData, setCsvData] = useState<any>([]);
    const csvLinkEl = useRef<any>(null);

    const handleDownload = (): void => {
        setCsvData([]);
        refetch();
    };

    useEffect(() => {
        if (promoCodeExportData) {
            setCsvData(promoCodeExportData);
        }
    }, [promoCodeExportData]);

    useEffect(() => {
        if (csvData.length > 0 && csvLinkEl.current) {
            csvLinkEl.current.link.click();
        }
    }, [csvData]);

    return id ? (
        <>
            {isFetching && <CustomLoader width={40} height={40} style={{ margin: 0 }} />}
            {!isFetching && (
                <>
                    <IconButton onClick={handleDownload}>{config.icon}</IconButton>
                    <CSVLink
                        filename={config.label.replace(':id', id.toString())}
                        data={csvData}
                        separator={';'}
                        ref={csvLinkEl}
                    />
                </>
            )}
        </>
    ) : (
        <></>
    );
};

export default AsyncCSVLinkAction;
