/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { /*SetStateAction,*/ useState } from 'react';
import { MainCard } from 'm6BoBuilder';
import {
    Button,
    CardActions,
    /*CardContent, Divider,*/ IconButton,
    Modal,
    Stack /*, Typography*/,
} from '@mui/material';
import { Close, PersonRemove } from '@mui/icons-material';

/*import TextField from '@mui/material/TextField';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';*/

import { User } from '../../interfaces/user.interface';

type SubmitData = {
    userId?: number;
    deleteDate?: Date | null;
};

type DeletModalType = { open: boolean; user: User; onClose: () => void; onSubmit: (data: SubmitData) => void };

const DeleteModal = ({ open, user, onClose, onSubmit }: DeletModalType): JSX.Element => {
    const [deleteDate /*, setDeleteDate*/] = useState(null);

    /*const handleDateChange = (date: SetStateAction<null>) => {
        setDeleteDate(date);
    };*/

    const handleSubmit = () => {
        onSubmit({ userId: user.id, deleteDate });
    };
    const handleClose = () => {
        onClose();
    };
    return (
        <Modal keepMounted={false} open={open}>
            <MainCard
                sx={{
                    position: 'absolute',
                    width: '80%',
                    maxWidth: 550,
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
                title={`Suppression user ${user.id}`}
                content={false}
                secondary={
                    <IconButton onClick={handleClose} size="large">
                        <Close fontSize="small" />
                    </IconButton>
                }
            >
                {/*<LocalizationProvider dateAdapter={AdapterDateFns}>
                    <CardContent sx={{ flexDirection: 'column' }}>
                        <DatePicker
                            label="Date de suppression"
                            toolbarFormat={'DD-MM-YYYY'}
                            value={deleteDate}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={handleDateChange}
                        />
                        <Typography variant="body1">Voulez-vous supprimer le compte {`${user.id}`} ?</Typography>
                        <Typography variant="body1">Cette action est irréversible.</Typography>
                    </CardContent>
                </LocalizationProvider>
                <Divider />*/}
                <CardActions>
                    <Stack justifyContent="flex-end" direction="row" sx={{ flex: 1 }}>
                        <form onSubmit={handleSubmit}>
                            <Button
                                color="error"
                                size="large"
                                variant="contained"
                                type={'submit'}
                                sx={{ mr: 2 }}
                                startIcon={<PersonRemove />}
                            >
                                {`Supprimer le compte`}
                            </Button>
                            <Button color="primary" onClick={handleClose} size="large">
                                {'Annuler'}
                            </Button>
                        </form>
                    </Stack>
                </CardActions>
            </MainCard>
        </Modal>
    );
};

export default DeleteModal;
