/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { MainCard } from 'm6BoBuilder';

import {
    Box,
    Button,
    CardContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Modal,
    Radio,
    RadioGroup,
} from '@mui/material';
import { Close, PersonOff } from '@mui/icons-material';
import { User } from 'interfaces/user.interface';
import moment from 'moment';
import { SyntheticEvent, useState } from 'react';

type SubmitData = {
    userId?: number;
    resiliationType: number;
    service: number;
};

type UnsubscribeModalType = { open: boolean; user: User; onClose: () => void; onSubmit: (data: SubmitData) => void };

const UnsubscribeModal = ({ open, user, onClose, onSubmit }: UnsubscribeModalType): JSX.Element => {
    const [selectedService, setSelectedService] = useState(0);

    const handleSubmit = (event: SyntheticEvent) => {
        event.preventDefault();
        const target = event.target as typeof event.target & {
            resiliationType: { value: string };
            service: { value: string };
        };
        onSubmit({
            userId: user.id,
            resiliationType: parseInt(target.resiliationType.value),
            service: parseInt(target.service.value),
        });
    };

    const handleClose = () => {
        onClose();
    };

    const handleChangeService = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value: number = parseInt(event.target.value);
        setSelectedService(value);
    };

    const unsubscribeLegalDate = () => {
        const offer = user?.lastOffers[getServiceLabel()];
        return moment(offer?.createdAt.toString()).add(15, 'd').format('DD/MM/YYYY');
    };

    const isInLegalInterval = () => {
        const offer = user?.lastOffers[getServiceLabel()];
        const now = moment();
        const legalDate = moment(offer?.createdAt.toString()).add(15, 'd');

        return legalDate > now;
    };

    const getServiceLabel = () => {
        switch (selectedService) {
            case 1:
                return 'Croqbody';
            case 2:
                return 'YogiClub';
            case 0:
            default:
                return 'Croqkilos';
        }
    };

    return (
        <Modal keepMounted={false} open={open}>
            <MainCard
                sx={{
                    position: 'absolute',
                    width: '80%',
                    maxWidth: 550,
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
                title={`Résiliation user ${user.id}`}
                content={false}
                secondary={
                    <IconButton onClick={handleClose} size="large">
                        <Close fontSize="small" />
                    </IconButton>
                }
            >
                <CardContent sx={{ flexDirection: 'column' }}>
                    <form onSubmit={handleSubmit}>
                        <FormControl component="fieldset" sx={{ display: 'block', mb: 2 }}>
                            <FormLabel component="legend" sx={{ mb: 1 }}>
                                Service à résilier
                            </FormLabel>
                            <RadioGroup name={'service'} onChange={handleChangeService} defaultValue={0}>
                                {user?.roles.includes('ROLE_CLIENT_CK') && (
                                    <FormControlLabel value={0} control={<Radio />} label={'CroqKilos'} />
                                )}
                                {user?.roles.includes('ROLE_CLIENT_CB') && (
                                    <FormControlLabel value={1} control={<Radio />} label={'CroqBody'} />
                                )}
                                {user?.roles.includes('ROLE_CLIENT_YG') && (
                                    <FormControlLabel value={1} control={<Radio />} label={'YogiClub'} />
                                )}
                            </RadioGroup>
                        </FormControl>
                        <FormControl component="fieldset" sx={{ display: 'block', mb: 4 }}>
                            <FormLabel component="legend" sx={{ mb: 1 }}>
                                Type de résiliation
                            </FormLabel>
                            <RadioGroup name={'resiliationType'} defaultValue={0}>
                                <FormControlLabel
                                    value={0}
                                    control={<Radio />}
                                    label={
                                        "Valider le désabonnement (Prise d'effet à la fin de l'offre, désactivation du renouvellement automatique)"
                                    }
                                />
                                <FormControlLabel
                                    value={1}
                                    control={<Radio />}
                                    label={'Effectuer une résiliation immédiate.'}
                                />
                                {isInLegalInterval() && (
                                    <FormControlLabel
                                        value={2}
                                        control={<Radio />}
                                        label={`Respecter les 15 jours légaux (prise d'effet le ${unsubscribeLegalDate()}) avec remboursement au prorata.`}
                                    />
                                )}
                            </RadioGroup>
                        </FormControl>
                        <Box>
                            <Button
                                color="error"
                                size="large"
                                variant="contained"
                                type={'submit'}
                                sx={{ mr: 2 }}
                                startIcon={<PersonOff />}
                            >
                                {`Résilier l'abonnement`}
                            </Button>
                            <Button color="primary" onClick={handleClose} size="large">
                                {'Annuler'}
                            </Button>
                        </Box>
                    </form>
                </CardContent>
            </MainCard>
        </Modal>
    );
};

export default UnsubscribeModal;
