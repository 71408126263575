import { createContext, useEffect /*, useState*/ } from 'react';
import ApiService from './services/ApiService';
import ScreenSwitcher from './components/navigation/ScreenSwitcher';
import { useLocation, useNavigate } from 'react-router';
import ThemeCustomization from './themes';
import { ConfigType } from './types/ConfigType';
import useAuth from './hooks/useAuth';

export const ApiContext = createContext<ApiService>({} as ApiService);
export const ApiLoading = createContext(false);
export const ConfigContext = createContext<ConfigType | null>(null);

type AppBoBuilderType = {
    config: ConfigType;
};

//const apiService: ApiService = new ApiService();

const AppBoBuilder = ({ config }: AppBoBuilderType): JSX.Element => {
    //apiService.loadConfig(config);
    //const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token') !== null);

    const { isAuth } = useAuth();
    //const [isApiLoading, setIsApiLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    /*useEffect(() => {
        //setLoggedIn(apiService.loggedIn);
        apiService.setIsLoadingCallback(setIsApiLoading);
        apiService.setLoggedInCallback(isAuth);
    }, []);*/

    useEffect(() => {
        if (!isAuth) {
            navigate('/login');
        } else {
            // location.pathname returns the previous screen
            // after login we go to /
            // on refresh we go to the previously stored path
            if (location.pathname === '/login') {
                navigate('/');
            }
        }
    }, [isAuth]);

    return (
        <ThemeCustomization customTheme={config?.customTheme}>
            {/*<ApiContext.Provider value={apiService}>*/}
            <ConfigContext.Provider value={config}>
                {/*<ApiLoading.Provider value={isApiLoading}>*/}
                <ScreenSwitcher />
                {/*</ApiLoading.Provider>*/}
            </ConfigContext.Provider>
            {/*</ApiContext.Provider>*/}
        </ThemeCustomization>
    );
};

export default AppBoBuilder;
