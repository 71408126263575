/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import { InputType, ResourceType, SelectRenderType } from 'm6BoBuilder';
import { PromoCodeProperties } from './PromoCode';
import { RessourceMode } from '../../m6BoBuilder/types/ResourceType';
import { IconFileDownload } from '@tabler/icons';
import AsyncCSVLinkAction from '../../components/action/AsynCSVLinkAction';
import {
    /*OFFER_DURATION_OPTIONS,*/ REDUCTION_TYPE,
    SERVICE_OPTIONS,
    USER_PROMO_PROFILES_OPTIONS,
} from '../../constants';
import OfferDuration from './OfferDuration';

const PromoCodeGroupProperties = {
    ...PromoCodeProperties,
    name: 'name',
    quantity: 'quantity',
    prefix: 'prefix',
};

const PromoCodeGroup: ResourceType = {
    resourcePath: 'bo-promo-code-group',
    placeHolder: {
        [PromoCodeGroupProperties.service]: 0,
        [PromoCodeGroupProperties.startedAt]: new Date(),
        [PromoCodeGroupProperties.endedAt]: null,
        [PromoCodeGroupProperties.discountType]: 1,
        [PromoCodeGroupProperties.offerDurations]: [],
        [PromoCodeGroupProperties.discount]: 0,
        [PromoCodeGroupProperties.name]: '',
        [PromoCodeGroupProperties.quantity]: 0,
        [PromoCodeGroupProperties.prefix]: '',
        [PromoCodeGroupProperties.profiles]: USER_PROMO_PROFILES_OPTIONS.map((el: Record<string, any>) => el.value),
        [PromoCodeGroupProperties.exclusive]: true,
    },

    validationSchema: yup.object({
        [PromoCodeGroupProperties.service]: yup.string().required('Ce champ est obligatoire'),
        [PromoCodeGroupProperties.name]: yup.string().required('Ce champ est obligatoire'),
        [PromoCodeGroupProperties.startedAt]: yup.date().required('Ce champ est obligatoire'),
        [PromoCodeGroupProperties.discountType]: yup.string().required('Ce champ est obligatoire'),
        [PromoCodeGroupProperties.offerDurations]: yup.array().min(1, 'Ce champ est obligatoire'),
        [PromoCodeGroupProperties.discount]: yup.number().min(1, 'Veuillez indiquer une quantité'),
        [PromoCodeGroupProperties.profiles]: yup.array().min(1, 'Veuillez indiquer un profil'),
        [PromoCodeGroupProperties.quantity]: yup.number().min(1, 'Veuillez indiquer un profil'),
    }),

    configForm: {
        label: 'Génération de codes promos en masse',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        beforeSubmit: (entity: Record<string, any>, context: RessourceMode): Record<string, any> => {
            entity.quantity = parseInt(entity.quantity);

            return entity;
        },
        listInputs: [
            {
                label: 'Service',
                property: PromoCodeGroupProperties.service,
                type: InputType.select,
                options: {
                    choices: SERVICE_OPTIONS,
                    render: SelectRenderType.radio,
                },
            },
            {
                label: 'Groupe',
                property: PromoCodeGroupProperties.name,
            },
            {
                label: 'Début',
                property: PromoCodeGroupProperties.startedAt,
                type: InputType.date,
            },
            {
                label: 'Date de fin',
                property: PromoCodeGroupProperties.endedAt,
                type: InputType.date,
            },
            {
                label: 'Type de réduction',
                property: PromoCodeGroupProperties.discountType,
                type: InputType.select,
                options: {
                    choices: REDUCTION_TYPE,
                    render: SelectRenderType.radio,
                },
            },
            {
                label: 'Montant',
                property: PromoCodeGroupProperties.discount,
                type: InputType.float,
            },
            /*{
                label: "Type d'abonnements",
                property: PromoCodeGroupProperties.offerDurations,
                type: InputType.select,
                options: {
                    choices: OFFER_DURATION_OPTIONS,
                    render: SelectRenderType.select,
                    multiple: true,
                },
            },*/
            {
                label: "Type d'abonnements",
                property: PromoCodeGroupProperties.offerDurations,
                type: InputType.entity,
                options: {
                    label: 'duration',
                    resource: OfferDuration.resourcePath,
                    multiple: true,
                },
            },
            {
                label: 'Profils',
                property: PromoCodeGroupProperties.profiles,
                type: InputType.select,
                options: {
                    choices: USER_PROMO_PROFILES_OPTIONS,
                    render: SelectRenderType.select,
                    multiple: true,
                },
            },
            {
                label: 'Quantité',
                property: PromoCodeGroupProperties.quantity,
            },
            {
                label: 'Préfixe',
                property: PromoCodeGroupProperties.prefix,
            },
            {
                label: 'Unique',
                property: PromoCodeGroupProperties.exclusive,
                type: InputType.boolean,
            },
        ],
    },
    configList: {
        label: 'Code promo en masse',
        listProperties: [
            {
                label: 'ID',
                property: PromoCodeGroupProperties.id,
                sortable: true,
            },
            {
                label: 'Groupe',
                property: PromoCodeGroupProperties.name,
                sortable: true,
            },
            {
                label: 'Quantité de code promos générée',
                property: PromoCodeGroupProperties.quantity,
                sortable: false,
            },
        ],
        allowAdd: true,
        filters: [
            {
                label: 'Groupe',
                property: PromoCodeGroupProperties.name,
            },
        ],
    },
    actions: {
        delete: false,
        edit: false,
        customs: [
            {
                config: {
                    label: 'promo_code_group_:id.csv',
                    icon: <IconFileDownload />,
                },
                component: AsyncCSVLinkAction,
            },
        ],
    },
};

export default PromoCodeGroup;
