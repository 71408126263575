/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { useUserStore } from '../services/State/User/user.store';
import { Config } from '../../config/config';

let clientCK: AxiosInstance;
let clientCB: AxiosInstance;
let clientYC: AxiosInstance;

export const getLocalAccessToken = (): string => {
    const userStore = useUserStore.getState();
    return userStore.token ?? '';
};

export const getLocalRefreshToken = (): string => {
    const userStore = useUserStore.getState();
    return userStore.refreshToken ?? '';
};

const axiosConfig: AxiosRequestConfig = {
    responseType: 'json',
    timeout: 30000,
};

const headers = {
    'Content-type': 'application/ld+json',
    Accept: 'application/ld+json',
};

export const getClient = (): AxiosInstance => {
    if (clientCK) {
        return clientCK;
    }
    clientCK = axios.create({
        ...axiosConfig,
        baseURL: Config.baseUrl,
    });

    clientCK.interceptors.request.use((config) => {
        const token = getLocalAccessToken();
        config.headers = {
            ...headers,
        };

        if (token) {
            config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
        }

        return config;
    });
    clientCK.interceptors.response.use(
        (value) => value,
        (error: AxiosError) => {
            throw error;
        },
    );

    return clientCK;
};

export const getClientMedia = () => {
    if (clientCK) {
        return clientCK;
    }
    clientCK = axios.create({
        baseURL: Config.baseUrl,
        responseType: 'json',
        timeout: 30000,
    });

    clientCK.interceptors.request.use((config) => {
        const token = getLocalAccessToken();
        if (token) {
            config.headers = {
                Authorization: `Bearer ${token}`,
                Accept: 'application/ld+json',
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache',
                Expires: '0',
                'Content-Type': 'multipart/form-data',
            };
            config.transformRequest = (formData: FormData) => formData;

            return config;
        }
    });
    clientCK.interceptors.response.use(
        (value) => value,
        (error: AxiosError) => {
            throw error;
        },
    );

    return clientCK;
};

export const getCBClient = (): AxiosInstance => {
    if (clientCB) {
        return clientCB;
    }
    clientCB = axios.create({
        ...axiosConfig,
        baseURL: Config.baseUrlCB,
    });

    clientCB.interceptors.request.use((config) => {
        const token = getLocalAccessToken();
        if (token) {
            config.headers = {
                ...headers,
                Authorization: `Bearer ${token}`,
            };

            return config;
        }
    });
    clientCB.interceptors.response.use(
        (value) => value,
        (error: AxiosError) => {
            throw error;
        },
    );

    return clientCB;
};

export const getYCClient = (): AxiosInstance => {
    if (clientYC) {
        return clientYC;
    }
    clientYC = axios.create({
        ...axiosConfig,
        baseURL: Config.baseUrlYC,
    });

    clientYC.interceptors.request.use((config) => {
        const token = getLocalAccessToken();
        if (token) {
            config.headers = {
                ...headers,
                Authorization: `Bearer ${token}`,
            };

            return config;
        }
    });
    clientYC.interceptors.response.use(
        (value) => value,
        (error: AxiosError) => {
            throw error;
        },
    );

    return clientYC;
};

export const get = <T>(url: string, config?: AxiosRequestConfig) =>
    getClient()
        .get<T>(url, config)
        .then((r) => r.data);

export const post = <T>(url: string, data?: unknown, config?: AxiosRequestConfig) =>
    getClient()
        .post<T>(url, data, config)
        .then((r) => r.data);

export const put = <T>(url: string, data?: unknown, config?: AxiosRequestConfig) =>
    getClient()
        .put<T>(url, data, config)
        .then((r) => r.data);

export const del = <T>(url: string, config?: AxiosRequestConfig) =>
    getClient()
        .delete<T>(url, config)
        .then((r) => r.data);

export const postMedia = <T>(url: string, data: FormData, config?: AxiosRequestConfig) =>
    getClientMedia()
        .post<T>(url, data, config)
        .then((r) => r.data);

// CB
export const getCB = <T>(url: string, config?: AxiosRequestConfig) =>
    getCBClient()
        .get<T>(url, config)
        .then((r) => r.data);

export const postCB = <T>(url: string, data?: unknown, config?: AxiosRequestConfig) =>
    getCBClient()
        .post<T>(url, data, config)
        .then((r) => r.data);

export const putCB = <T>(url: string, data?: unknown, config?: AxiosRequestConfig) =>
    getCBClient()
        .put<T>(url, data, config)
        .then((r) => r.data);

// YC
export const getYC = <T>(url: string, config?: AxiosRequestConfig) =>
    getYCClient()
        .get<T>(url, config)
        .then((r) => r.data);

export const postYC = <T>(url: string, data?: unknown, config?: AxiosRequestConfig) =>
    getYCClient()
        .post<T>(url, data, config)
        .then((r) => r.data);

export const putYC = <T>(url: string, data?: unknown, config?: AxiosRequestConfig) =>
    getYCClient()
        .put<T>(url, data, config)
        .then((r) => r.data);

/*

export const getById = <T>(url: string, config?: AxiosRequestConfig) =>
    getClient()
        .get<T>(url, config)
        .then((r) => r.data);*/

/*
export const get = <T>(url: string, config?: AxiosRequestConfig) =>
    getCBClient()
        .get<T>(url, config)
        .then((r) => r.data);

export const getCK = <T>(url: string, config?: AxiosRequestConfig) =>
    getCKClient()
        .get<T>(url, config)
        .then((r) => r.data);*/
/*
export const postCK = <T>(url: string, data?: unknown, config?: AxiosRequestConfig) =>
    getCKClient()
        .post<T>(url, data, config)
        .then((r) => r.data);

export const putCk = <T>(url: string, data?: unknown, config?: AxiosRequestConfig) =>
    getCKClient()
        .put<T>(url, data, config)
        .then((r) => r.data);

export const del = <T>(url: string, config?: AxiosRequestConfig) =>
    getCBClient()
        .delete<T>(url, config)
        .then((r) => r.data);

export const postMedia = <T>(url: string, data?: unknown, config?: AxiosRequestConfig) =>
    getCBClientMedia()
        .post<T>(url, data, config)
        .then((r) => r.data);
*/
