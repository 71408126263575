/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';

import { DateConverter, InputType, ResourceType, SelectRenderType, BooleanConverter } from 'm6BoBuilder';
import SubformRecipeIngredient from '../../components/form/subform/SubformRecipeIngredient';
import { IngredientProperties } from './Ingredient';
import { PUBLISHED_STATUS, PUBLISHED_STATUS_OPTIONS } from '../../constants';
import Tag, { TagProperties } from './Tag';

const RecipeVersionProperties = {
    id: 'id',
    title: 'title',
    recipe: 'recipe',
    kcal: 'kcal',
    personNumber: 'personNumber',
    portion: 'portion',
    versionIngredients: 'versionIngredients',
    user: 'user',
    status: 'status',
    price: 'price',
    totalIngredients: 'totalIngredients',
    tags: 'tags',
    publishedAtStart: 'publishedAtStart',
    createdBy: 'createdBy',
    updatedBy: 'updatedBy',
    createdAt: 'createdAt',
    updatedAt: 'updatedAt',
};

const RecipeVersion: ResourceType = {
    resourcePath: 'bo-recipe_versions',
    placeHolder: {
        [RecipeVersionProperties.title]: '',
        [RecipeVersionProperties.recipe]: [],
        [RecipeVersionProperties.kcal]: 0,
        [RecipeVersionProperties.personNumber]: null,
        [RecipeVersionProperties.portion]: null,
        [RecipeVersionProperties.versionIngredients]: [],
        [RecipeVersionProperties.price]: 0,
        [RecipeVersionProperties.user]: null,
        [RecipeVersionProperties.status]: 0,
        [RecipeVersionProperties.publishedAtStart]: null,
        [RecipeVersionProperties.totalIngredients]: 0,
        [IngredientProperties.tags]: [],
    },
    validationSchema: yup.object({
        [RecipeVersionProperties.title]: yup.string().required('Ce champ est obligatoire'),
        [RecipeVersionProperties.portion]: yup.number().required('Ce champ est obligatoire'),
        [RecipeVersionProperties.personNumber]: yup.number().required('Ce champ est obligatoire'),
    }),
    configForm: {
        label: 'Recette',
        listInputs: [
            {
                label: 'Nom',
                property: RecipeVersionProperties.title,
            },
            {
                label: 'Publié',
                property: RecipeVersionProperties.status,
                type: InputType.select,
                options: {
                    choices: PUBLISHED_STATUS_OPTIONS,
                    render: SelectRenderType.select,
                },
            },
            {
                label: 'Publiée le',
                property: RecipeVersionProperties.publishedAtStart,
                type: InputType.date,
            },
            {
                label: "Kcal (calculé automatiquement lors de l'enregistrement)",
                property: RecipeVersionProperties.kcal,
                type: InputType.integer,
                options: {
                    readOnly: true,
                },
            },
            {
                label: 'Prix (en €)',
                property: RecipeVersionProperties.price,
                type: InputType.float,
                options: {
                    readOnly: true,
                },
            },
            /*{
                label: 'Temps de préparation',
                property: RecipeVersionProperties.preparationTime,
                type: InputType.integer,
            },
            {
                label: 'Temps de cuisson',
                property: RecipeVersionProperties.cookingTime,
                type: InputType.integer,
            },*/
            {
                label: 'Portion',
                property: RecipeVersionProperties.portion,
                type: InputType.integer,
            },
            {
                label: 'Nombre de personnes',
                property: RecipeVersionProperties.personNumber,
                type: InputType.integer,
            },
            {
                label: 'Ingrédients',
                property: RecipeVersionProperties.versionIngredients,
                type: InputType.subform,
                options: {
                    subform: SubformRecipeIngredient,
                    multiple: true,
                    sortable: {
                        weight: 'position',
                    },
                },
            },
            {
                label: 'Tags',
                property: IngredientProperties.tags,
                type: InputType.entity,
                options: {
                    resource: Tag.resourcePath,
                    label: TagProperties.name,
                    multiple: true,
                },
            },
        ],
    },
    configList: {
        label: 'Recettes',
        listProperties: [
            {
                label: '#',
                property: RecipeVersionProperties.id,
                sortable: true,
                styles: {
                    width: '3%',
                },
            },
            {
                label: 'Nom',
                property: RecipeVersionProperties.title,
                sortable: true,
                styles: {
                    minWidth: '25%',
                },
            },
            /*{
                label: "Nombre d'ingrédients",
                property: RecipeVersionProperties.totalIngredients,
            },*/
            {
                label: 'Auteur',
                property: RecipeVersionProperties.user,
                transform: (elt: Record<string, string>): string => elt.email,
                sortable: true,
                styles: {
                    width: '10%',
                },
            },
            {
                label: 'Publié',
                property: RecipeVersionProperties.status,
                sortable: false,
                transform: (elt: number): JSX.Element =>
                    BooleanConverter.display(elt === PUBLISHED_STATUS.PUBLISHED ? true : false),
            },
            {
                label: 'Date de publication',
                property: RecipeVersionProperties.publishedAtStart,
                transform: DateConverter.display,
                sortable: true,
            },
        ],
        allowAdd: true,
        filters: [
            {
                label: '#ID',
                property: RecipeVersionProperties.id,
                type: InputType.text,
            },
            {
                label: 'Nom',
                property: RecipeVersionProperties.title,
            },
            {
                label: 'Tags',
                property: `${IngredientProperties.tags}.id`,
                type: InputType.entity,
                options: {
                    resource: Tag.resourcePath,
                    label: TagProperties.name,
                    multiple: true,
                },
            },
            {
                label: 'Statut',
                property: RecipeVersionProperties.status,
                type: InputType.select,
                options: {
                    choices: PUBLISHED_STATUS_OPTIONS,
                    render: SelectRenderType.select,
                },
            },
        ],
    },
    actions: {
        delete: true,
    },
};

export default RecipeVersion;
export { RecipeVersionProperties };
