/* eslint-disable @typescript-eslint/no-explicit-any */
import { /*useContext,*/ useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Grid } from '@mui/material';
import { ObjectSchema } from 'yup';

import { ConfigFormType } from '../types/forms/ConfigFormType';
import { ActionsType } from '../types/ActionsType';
import { RessourceMode } from '../types/ResourceType';

import { flashMessage, flashType } from './Flash';
import DefaultForm from './form/DefaultForm';
import WhiteBox from './styled/WhiteBox';
import CustomLoader from './CustomLoader';
import { useCreateResource, useGetResource, useUpdateResourceById } from '../services/useQueries';

type ResourceEditorProps = {
    id?: string | number;
    resourceType: string;
    config: ConfigFormType;
    actions?: ActionsType;
    resourceService?: string;
    validationSchema: ((context: RessourceMode) => ObjectSchema<any>) | ObjectSchema<any>;
    placeHolder: Record<string, unknown>;
    defaultValues?: {
        [key: string]: any;
    };
    showHelpCta?: boolean;
    resource?: {
        [key: string]: any;
    };
    setResourceValue?: (entity: any) => void;
    noAction?: boolean;
    errorsAll?: Record<string, any>;
    inputSize?: number;
};

const ResourceEditor = ({
    id,
    resourceType,
    config,
    actions,
    validationSchema,
    placeHolder,
    defaultValues = {},
    showHelpCta,
    resource,
    setResourceValue,
    noAction,
    errorsAll,
    resourceService = 'ck',
    inputSize = 12,
}: ResourceEditorProps): JSX.Element => {
    let validationObjectSchema: ObjectSchema<any>;
    if (typeof validationSchema === 'function') {
        validationObjectSchema = validationSchema(id === undefined ? RessourceMode.Create : RessourceMode.Edit);
    } else {
        validationObjectSchema = validationSchema;
    }

    const [entity, setEntity] = useState({ ...placeHolder, ...defaultValues });
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const EditForm = config.editForm ?? DefaultForm;

    // User has rights to edit
    if (id !== undefined && false === actions?.edit) {
        flashMessage('Edition non autorisée', flashType.ERROR);
        navigate(`/${resourceType}`);
    }

    const { isFetching, data: resourceData } = useGetResource(resourceType, id);
    const { mutateAsync: createResourceMutateAsync } = useCreateResource();
    const { mutateAsync: updatecreateResourceMutateAsync } = useUpdateResourceById();

    useEffect(() => {
        window.scrollTo(0, 0);
        //refetch();
    }, []);

    /*const initValues = (data: Record<string, any>): void => {
        const filtered = Object.getOwnPropertyNames(placeHolder).reduce(
            (obj, key) => ({ ...obj, [key]: data.data[key] }),
            {},
        );
        setEntity({ ...filtered, ...defaultValues });
    };*/

    useEffect(() => {
        if (resourceData) {
            setEntity({ ...defaultValues, ...resourceData });
            if (setResourceValue) {
                setResourceValue({ ...defaultValues, ...resourceData });
            }
        }
    }, [resourceData]);

    const handleSubmit = async (ev: React.SyntheticEvent) => {
        // Prevent form submit
        ev.preventDefault();
        // don't submit bad form
        if (ev.target !== ev.currentTarget) {
            return;
        }
        // Yup form validation
        let values = { ...defaultValues, ...entity };
        const isFormValid = await validationObjectSchema.isValid(values);
        // If form invalide, go back to top and show error alert
        if (!isFormValid) {
            validationObjectSchema.validate(values, { abortEarly: false }).catch((err: any) => {
                const errors = err.inner.reduce((acc: any, error: any) => {
                    return {
                        ...acc,
                        [error.path]: error.message,
                    };
                }, {});
                setErrors(errors);
            });
            window.scrollTo(0, 0);
            flashMessage('Certains champs sont invalides', flashType.ERROR);
        } else {
            if (config.beforeSubmit) {
                values = config.beforeSubmit(values, id === undefined ? RessourceMode.Create : RessourceMode.Edit);
            }
            setErrors({}); // Reset errors
            //setLoading(true);
            if (id === undefined) {
                // empty id ==> resource creation
                createResourceMutateAsync({
                    resourcePath: resourceType,
                    data: values,
                    callback: async (data: any) => {
                        let r = true;
                        if (config.afterSubmit) {
                            r = await config.afterSubmit(data, RessourceMode.Create);
                        }

                        if (r) {
                            navigate(`/${resourceType}`);
                        }
                    },
                });
            } else {
                // existing id ==> resource update
                updatecreateResourceMutateAsync({
                    resourcePath: resourceType,
                    resourceId: id,
                    data: values,
                    callback: async (data: any) => {
                        let r = true;
                        if (config.afterSubmit) {
                            r = await config.afterSubmit(data, RessourceMode.Edit);
                        }

                        if (r) {
                            navigate(`/${resourceType}/${id}`);
                        }
                    },
                });
            }
        }
    };

    // Validate single field on change
    let timer: ReturnType<typeof setTimeout>;
    const handleEntityUpdate = (entity: Record<string, unknown>, field: string) => {
        //if (!resource) {
        setEntity(entity);
        //}
        if (setResourceValue) {
            setResourceValue(entity);
        }
        if (typeof validationObjectSchema.fields[field] !== 'undefined') {
            clearTimeout(timer);
            timer = setTimeout(() => {
                validationObjectSchema
                    .validateAt(field, { [field]: entity[field] })
                    .then(() => {
                        // Field valid, remove from errors array
                        if (field in errors) {
                            setErrors({ ...errors, [field]: false });
                        }
                    })
                    .catch((err: any) => {
                        console.log('error : ', field);
                        // Field not valid, add to errors array
                        setErrors({ ...errors, [err.path]: err.message });
                    });
            }, 200);
        }
    };

    return (
        <>
            <WhiteBox sx={{ paddingX: 2, paddingY: 4 }}>
                {!isFetching && (
                    <form onSubmit={handleSubmit}>
                        <EditForm
                            entity={resource ? resource : entity}
                            resourceType={resourceType}
                            actions={actions}
                            noAction={noAction}
                            errors={{ ...errors, ...errorsAll }}
                            config={{
                                ...config,
                                listInputs: config.listInputs.map((elem) => {
                                    return { ...elem, options: { ...elem.options, service: resourceService } };
                                }),
                            }}
                            handleEntityUpdate={handleEntityUpdate}
                            inputSize={inputSize}
                        />
                    </form>
                )}
                {isFetching && (
                    <Grid container justifyContent="center" alignItems="center">
                        <CustomLoader />
                    </Grid>
                )}
            </WhiteBox>
        </>
    );
};
export default ResourceEditor;
