import { ChangeEvent, useEffect, useState } from 'react';
import { FormElementPropsType } from '../../types/forms/FormElementPropsType';
import { Box, Button, Input, Typography, Fab } from '@mui/material';
import { Add, Download } from '@mui/icons-material';
import { Edit } from '@mui/icons-material';

import { useUploadMedia } from 'm6BoBuilder/services/useQueries';
import { HydraResource } from 'm6BoBuilder/interfaces/hydra.interface';

const FormFile = ({ entity, updateEntity, configLine }: FormElementPropsType): JSX.Element => {
    if (!configLine.options?.resource) {
        throw new Error(`Missing resource type in "${configLine.property}" field options`);
    }

    const { mutateAsync: UploadMutateAsync } = useUploadMedia();

    const [fileUrl, setFileUrl] = useState<string>('');
    const resource = configLine.options.resource;

    useEffect(() => {
        if (entity[configLine.property]) {
            setFileUrl(process.env.REACT_APP_API_URL + '/' + entity[configLine.property].webPath);
        }
    }, [entity[configLine.property]]);

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.item(0);
        if (undefined === file || null === file) {
            return;
        }
        const reader = new FileReader();
        reader.onloadend = async () => {
            if (reader.result == null) {
                return;
            }
            await UploadMutateAsync({
                resourcePath: resource,
                file,
                onSuccess: (data: HydraResource) => {
                    entity[configLine.property] = data;
                    updateEntity(entity, configLine.property);
                    setFileUrl(process.env.REACT_APP_API_URL + '/' + data.webPath);
                },
            });
        };
        reader.readAsDataURL(file);
    };

    const id = `select-file-${configLine.property}`;

    return (
        <Box>
            <Typography component="h2" variant="h6" mb={1}>
                {configLine.label}
            </Typography>
            <Input type="file" style={{ display: 'none' }} onChange={handleFileUpload} id={id} />
            {fileUrl ? (
                <>
                    <Fab color="primary" size="small" style={{ marginLeft: '12px' }}>
                        <label
                            htmlFor={id}
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                        >
                            <Edit fontSize="small" />
                        </label>
                    </Fab>
                    <Fab color="primary" size="small" style={{ marginLeft: '12px' }}>
                        <a
                            href={fileUrl}
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                textDecoration: 'none',
                                color: 'white',
                            }}
                            rel="noreferrer"
                            target="_blank"
                        >
                            <Download fontSize="small" />
                        </a>
                    </Fab>
                </>
            ) : (
                <label htmlFor={id}>
                    <Button variant="contained" color="primary" component="span" startIcon={<Add />}>
                        Ajouter un fichier
                    </Button>
                </label>
            )}
        </Box>
    );
};
export default FormFile;
