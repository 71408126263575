import { Stack, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { MouseEvent } from 'react';

const UserListStripeCell = (element: string): JSX.Element => {
    return (
        <>
            <Stack>
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Typography component="p">{element}</Typography>
                    {element && (
                        <OpenInNewIcon
                            onClick={(event: MouseEvent<SVGSVGElement>) => {
                                event?.preventDefault();
                                event?.stopPropagation();

                                window.open(
                                    `${process.env.STRIPE_PROVIDER_DASHBOARD_URL}/customers/${element}`,
                                    '_blank',
                                );
                            }}
                            sx={{ fontSize: 14 }}
                        />
                    )}
                </Stack>
            </Stack>
        </>
    );
};

export default UserListStripeCell;
