import { create } from 'zustand';
import { AppState } from './app.interface';

export const useAppStore = create<AppState>((set) => ({
    _hasHydrated: false,
    isLoadingRefresh: false,
    testStoreValue: false,
    setHasHydrated: (state) => {
        set({
            _hasHydrated: state,
        });
    },
    setIsloadingRefresh: (state) => {
        set({
            isLoadingRefresh: state,
        });
    },
    setTestStoreValue: (status) =>
        set({
            testStoreValue: status,
        }),
}));
