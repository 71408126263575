import { AppState } from './app.interface';
import { useAppStore } from './app.store';

const appService = (state: AppState) => {
    return {
        _hasHydrated: state._hasHydrated,
        isLoadingRefresh: state.isLoadingRefresh,
        testStoreValue: state.testStoreValue,
        setIsloadingRefresh: (status: boolean) => state.setIsloadingRefresh(status),
        setTestStoreValue: (value: boolean) => state.setTestStoreValue(value),
    };
};

export const useAppService = () => {
    return useAppStore(appService);
};
