import { AppBoBuilder, ConfigType } from 'm6BoBuilder';
import { ROLES } from 'm6BoBuilder/constants';
import navigation from './config/navigation';
import screens from './config/screens';
import generateCustomRouteList from './config/CustomRoutes';
import http from './http-client';
import httpCB from './http-client-cb';
import httpYG from './http-client-yg';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import TimeAgo from 'javascript-time-ago';

import fr from 'javascript-time-ago/locale/fr';
import { AxiosInstance } from 'axios';
import themeCroqkilos from 'themes/croqkilos';

TimeAgo.addDefaultLocale(fr);

const clients: Record<string, AxiosInstance> = {
    ck: http,
};

if (httpYG) {
    clients['yg'] = httpYG;
}

if (httpCB) {
    clients['cb'] = httpCB;
}

const config: ConfigType = {
    allowedRoles: [ROLES.ROLE_ADMIN],
    navigation: navigation,
    screens: screens,
    getCustomRouteList: generateCustomRouteList,
    clients: clients,
    customTheme: themeCroqkilos,
};

const App = (): JSX.Element => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    });

    return (
        <QueryClientProvider client={queryClient}>
            <AppBoBuilder config={config} />
        </QueryClientProvider>
    );
};

export default App;
