import { Grid } from '@mui/material';
import { gridSpacing } from '../berry/store/constant';

//import TotalUserCard from '../../components/charts/TotalUserCard';
//import UserCountCard from '../../components/charts/UserCountCard';
//import { IconUserCircle } from '@tabler/icons';
/*import TotalPieChartCard from '../../components/charts/TotalPieChartCard';
import NewUserCard from '../../components/charts/NewUserCard';

import TotalOrderLineChartCard from '../../components/charts/TotalOrderLineChartCard';*/

import IconNumberCard from '../berry/ui-component/cards/IconNumberCard';
import { useTheme } from '@mui/material/styles';
import { Help as HelpIcon } from '@mui/icons-material';

function LandingScreen(): JSX.Element {
    const theme = useTheme();

    return (
        <>
            <Grid container>
                <Grid sx={{ mt: gridSpacing }}>
                    <IconNumberCard
                        title={`Si vous souhaitez afficher quelque chose sur cette page, données (nombres d'utilisateurs, d'abonnées), stats (répartition des abonnements, des types de menus, etc... Créez une US :)`}
                        primary={''}
                        color={theme.palette.primary.dark}
                        iconPrimary={HelpIcon}
                    />
                </Grid>
                {/*<Grid container spacing={gridSpacing}>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <TotalUserCard title={'Total des abonnés'} resource="totalSubs" />
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <TotalUserCard title={'Total des utilisateurs'} resource="totalUsers" />
                    </Grid>
                    {/*<Grid item lg={4} md={6} sm={6} xs={12}>
                        <UserCountCard
                            primary={`primary`}
                            secondary={`secondary`}
                            iconPrimary={IconUserCircle}
                            color={`#654321`}
                        />
                    </Grid>* /}
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <TotalPieChartCard
                            title={'Types des codes promos'}
                            actions={[
                                {
                                    label: 'M',
                                    resource: 'actualPromoCodes',
                                },
                                {
                                    label: 'M-1',
                                    resource: 'lastMonthPromoCodes',
                                },
                                {
                                    label: 'N-1',
                                    resource: 'lastYearPromoCodes',
                                },
                            ]}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <NewUserCard
                            title={'Nouveaux abonnés'}
                            actions={[
                                {
                                    label: 'M',
                                    resource: 'actualSubs',
                                },
                                {
                                    label: 'M-1',
                                    resource: 'lastMonthSubs',
                                },
                                {
                                    label: 'N-1',
                                    resource: 'lastYearSubs',
                                },
                            ]}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Grid>
                            <TotalOrderLineChartCard
                                title={'Revenus'}
                                actions={[
                                    {
                                        label: 'M',
                                        resource: 'thisMonthCA',
                                    },
                                    {
                                        label: 'M-1',
                                        resource: 'lastMonthCA',
                                    },
                                    {
                                        label: 'N-1',
                                        resource: 'lastYearCA',
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <TotalPieChartCard
                            title={"Total durées d'abonnements"}
                            actions={[
                                {
                                    label: '',
                                    resource: 'totalOfferDurations',
                                },
                            ]}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <NewUserCard
                            title={'Nouveaux utilisateurs'}
                            actions={[
                                {
                                    label: 'M',
                                    resource: 'actualNewUsers',
                                },
                                {
                                    label: 'M-1',
                                    resource: 'lastMonthNewUsers',
                                },
                                {
                                    label: 'N-1',
                                    resource: 'lastYearNewUsers',
                                },
                            ]}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <TotalPieChartCard
                            title={'Types de régimes'}
                            actions={[
                                {
                                    resource: 'totalDietTypes',
                                },
                            ]}
                        />
                    </Grid>
                </Grid>*/}
            </Grid>
        </>
    );
}

export default LandingScreen;
