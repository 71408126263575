/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
//import User from 'config/resources/User';
import Offer from 'config/resources/Offer';
import { TableList, ResourceEditor, MainCard, ActionType, ActionTypeProps } from 'm6BoBuilder';
import { Edit, Close, Add } from '@mui/icons-material';
import { Button, CardContent, IconButton, Modal, Typography } from '@mui/material';
import { MouseEvent, /*useContext,*/ useEffect, useState } from 'react';
import { RessourceMode } from '../m6BoBuilder/types/ResourceType';

type UserOffersCardPropsType = {
    userId: number | string;
};

const UserOffersCard = ({ userId }: UserOffersCardPropsType): JSX.Element => {
    const [offerId, setOfferId] = useState(undefined);
    const [editModal, setEditModal] = useState(false);
    const [refresh, setRefresh] = useState(false);
    //const [subModal, setSubModal] = useState(false);

    const openEmptyEditModal = () => {
        setOfferId(undefined);
        setEditModal(true);
        setRefresh(false);
    };

    const EditOffer: ActionType = ({ id }: ActionTypeProps) => {
        const initOfferEditForm = (id: any) => (event: MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            event?.stopPropagation();
            setOfferId(id);
            setEditModal(true);
            setRefresh(false);
        };
        return (
            <IconButton onClick={initOfferEditForm(id)} size="large">
                <Edit fontSize="small" />
            </IconButton>
        );
    };

    useEffect(() => {
        if (!editModal) {
            setOfferId(undefined);
        }
    }, [editModal]);

    /*useEffect(() => {
        if (subModal) {
            window.location.reload();
        }
    }, [subModal]);*/

    return (
        <>
            <Typography component="h3" variant="h3" sx={{ mt: 4, mb: 4 }}>
                Liste des abonnements
            </Typography>
            <TableList
                resource={Offer}
                config={Offer.configList}
                actions={[EditOffer]}
                listPath={`users/${userId}/offers`}
                showAddCta={false}
                navigateOnClick={false}
                forceRefresh={refresh}
            />
            <Button color="primary" size="large" variant="contained" startIcon={<Add />} onClick={openEmptyEditModal}>
                Créer un abonnement
            </Button>

            {editModal && (
                <Modal
                    keepMounted={false}
                    open={editModal}
                    sx={{ overflow: 'scroll' }}
                    onBackdropClick={() => setEditModal(false)}
                >
                    <MainCard
                        sx={{
                            width: '80%',
                            mr: 'auto',
                            ml: 'auto',
                            mt: '5vh',
                            mb: '5vh',
                        }}
                        title={offerId ? `Editer abonnement #${offerId}` : `Créer un nouvel abonnement`}
                        content={false}
                        secondary={
                            <IconButton
                                onClick={() => {
                                    setEditModal(false);
                                }}
                                size="large"
                            >
                                <Close fontSize="small" />
                            </IconButton>
                        }
                    >
                        <CardContent>
                            <ResourceEditor
                                id={offerId}
                                resourceType={Offer.resourcePath}
                                config={{
                                    ...Offer.configForm,
                                    ...{
                                        afterSubmit: (
                                            entity: Record<string, unknown>,
                                            context: RessourceMode,
                                        ): boolean => {
                                            /*if (context === RessourceMode.Create) {
                                                setSubModal(true);
                                                setRefresh(true);
                                                setEditModal(false);
                                            }*/
                                            setRefresh(true);
                                            setEditModal(false);

                                            return false;
                                        },
                                    },
                                }}
                                actions={Offer.actions}
                                validationSchema={Offer.validationSchema}
                                placeHolder={Offer.placeHolder}
                                defaultValues={{
                                    user: `users/${userId}`,
                                }}
                                showHelpCta={false}
                            />
                        </CardContent>
                    </MainCard>
                </Modal>
            )}
        </>
    );
};

export default UserOffersCard;
