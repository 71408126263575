import { MainCard, ResourceEditor, ScreenPropsType, ScreenType } from 'm6BoBuilder';

// Libs
import { Box, CardContent, Tab, Tabs } from '@mui/material';
import { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// queries
import RecipeVersionInfos from './RecipeVersionInfos';
import RecipeVersion from 'config/resources/RecipeVersion';
import { RecipeType } from 'models/Recipe';

interface TabPanelProps {
    children?: ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

const RecipeScreen: ScreenType = ({ resource }: ScreenPropsType): JSX.Element => {
    const config = resource.configForm;

    const { id } = useParams<string>();

    const [recipeData, updateRecipeData] = useState<RecipeType | null>(null);
    // tabs
    const [tabs, setTabs] = useState<any[]>([]);
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        const defaultTabs = [];
        defaultTabs.push({
            index: 'original',
            tab: <Tab label="Originale" {...a11yProps(tabs.length)} key="original" />,
            content: (
                <ResourceEditor
                    id={id}
                    resourceService={resource.service}
                    resourceType={resource.resourcePath}
                    config={config}
                    validationSchema={resource.validationSchema}
                    placeHolder={resource.placeHolder}
                    showHelpCta={false}
                    setResourceValue={(data) => {
                        if (updateRecipeData) {
                            updateRecipeData(data);
                        }
                    }}
                />
            ),
        });
        if (recipeData) {
            defaultTabs.push(
                ...recipeData.versions.map((element, index) => {
                    return createVersionTab(index + 1, element.id);
                }),
            );
        }
        defaultTabs.push({
            index: 'new_version',
            tab: <Tab label="Nouvelle version" {...a11yProps(tabs.length)} key="new_version" />,
            content: <></>,
        });
        setTabs(defaultTabs);
    }, [recipeData]);

    //

    const createVersionTab = (index: number, id?: number) => {
        return {
            index: `version_${index}`,
            tab: <Tab label={`Version ${index}`} {...a11yProps(tabs.length)} key={index} />,
            content: <RecipeVersionInfos id={id} resource={RecipeVersion} recipe={recipeData} />,
        };
    };

    const addNewVersionTab = (index: number) => {
        if (tabs) {
            const newTab = createVersionTab(index);
            setTabs([...tabs.slice(0, index), newTab, ...tabs.slice(index)]);
        }
    };

    // HANDLERS

    const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
        if (tabs[newValue] && tabs[newValue].index === 'new_version') {
            addNewVersionTab(newValue);
        }
        setTabValue(newValue);
    };

    return (
        <MainCard>
            <CardContent
                sx={{
                    width: '100%',
                }}
            >
                <Box sx={{ width: '100%' }} key="tabs_container">
                    <Box>
                        <Tabs value={tabValue} onChange={handleChangeTab} aria-label="">
                            {tabs.map((elt: any) => elt.tab)}
                        </Tabs>
                    </Box>
                    {tabs.map((elt: any, index: number) => {
                        return (
                            <CustomTabPanel key={`tab_${index}`} value={tabValue} index={index}>
                                {elt.content}
                            </CustomTabPanel>
                        );
                    })}
                </Box>
            </CardContent>
        </MainCard>
    );
};

export default RecipeScreen;
